<template>
  <div v-if="!isThirdPartyLogin" id="LoginLayout" class="all flex-column">
    <div class="head flex-column align-center">
      <div class="middle logo">
        <img src="../assets/logo2.png" alt="" />
        <span class="title">威智造</span>
      </div>
    </div>
    <div
      class="body flex-column align-center"
      :style="{ minHeight: minHeight + 'px' }"
    >
      <div class="middle flex-row justify-between">
        <img src="@/assets/beijing.png" class="left" />
        <div
          class="right"
          :style="{
            minHeight: minHeight,
          }"
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { getUrlParam } from '@/utils/queryParam.js'

export default {
  name: 'LoginLayout',
  data () {
    return {
      minHeight: 0,
      urlQueryParams: {
        sign: getUrlParam('sign'),
        telephone: getUrlParam('telephone'),
        timestamp: getUrlParam('timestamp'),
        nonce: getUrlParam('nonce'),
      },
    }
  },
  created () {
    this.minHeight = innerHeight - 64
  },
  methods: {

  },
  computed: {
    // 只有进行智维云三方登录且当前路由为登录页时才隐藏当前组件
    isThirdPartyLogin () {
      const { sign, telephone, timestamp, nonce } = this.urlQueryParams
      return sign && telephone && timestamp && nonce && this.routePathMatch
    },
    routePathMatch () {
      return this.$route.name === 'account-login'
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/common.scss";
.all {
  background: #f8f8f8;
  .head {
    width: 100%;
    height: 64px;
    background: #fff;
    .middle {
      height: 100%;
      width: 900px;
      }
      .logo {
        position: relative;
        top: 18px;
        img {
          width: 30px;
          height: 30px;
        }
        .title {
          width: 60px;
          height: 21px;
          opacity: 1;
          font-size: 18px;
          font-family: HuXiaoBo-NanShen, HuXiaoBo-NanShen-Regular;
          font-weight: 600;
          text-align: left;
          color: #134ccf;
          position: relative;
          left:10px;
          top: 4px;
        }
      }
    }
  .body {
    width: 100%;
    .middle {
      margin-top: 50px;
      height: 100%;
      width: 900px;
      .left {
        width: 400px;
        height: 550px;
      }
      .right {
        width: 500px;
        background: #fff;
        height: 550px;
        overflow: hidden;
      }
    }
  }
}
</style>
