// 状态声明
const state = () => ({
  connection: {},
})

const getters = {}

const mutations = {
  setConnection (state, { connection }) {
    state.connection = connection
    localStorage.setItem('connection', JSON.stringify(connection))
  },
}

const actions = {
  setConnection ({ state, commit }, connection) {
    commit('setConnection', { connection: connection })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
