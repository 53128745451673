import { isTokenValid } from '@/utils/TokenUtils'
// 状态声明
const state = () => ({
  token: {},
  language: null,
  tenant: {},
  isLogin: false,
})

const getters = {}

const mutations = {
  setToken (state, { tokenData }) {
    let data = {}
    if (tokenData) {
      const token = JSON.parse(tokenData)
      data = {
        ...token,
        expire_time: new Date().valueOf() + token.expires_in,
        scope: undefined,
      }
    }
    state.token = data
    state.isLogin = isTokenValid(data)
    localStorage.setItem('token', JSON.stringify(data))
  },
  setLanguage (state, language) {
    state.language = language
  },
  setTenant (state, { tenant }) {
    state.tenant = tenant
    localStorage.setItem('tenant', tenant.tenantId)
  },
  logout (state) {
    state.token = null
    state.isLogin = false
  },
}

const actions = {
  setToken ({ state, commit }, tokenData) {
    commit('setToken', { tokenData: tokenData })
  },
  setLanguage ({ state, commit }, language) {
    commit('setLanguage', language)
  },
  setTenant ({ state, commit }, tenant) {
    commit('setTenant', { tenant: tenant })
  },
  logout ({ state, commit }) {
    commit('logout')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
