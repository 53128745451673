export default function dispatchEventStroage () {
  const signSetItem = localStorage.setItem
  localStorage.setItem = function (key, val) {
    const setEvent = new Event('setItemEvent')
    setEvent.key = key
    setEvent.newValue = val
    window.dispatchEvent(setEvent)
    signSetItem.apply(this, arguments)
  }
}

export function createLocalStorageMock () {
  const storage = window.localStorage
  const localStorageMock = {
    setItem: function (key, value) {
      const setItemEvent = new Event('setItemEventNew')
      const oldValue = storage[key]
      setItemEvent.key = key
      setItemEvent.newValue = value
      setItemEvent.oldValue = oldValue
      window.dispatchEvent(setItemEvent)
      storage[key] = value
      return true
    },
    getItem: function (key) {
      return storage[key]
    },
    removeItem: function (key) {
      storage.removeItem(key)
      return true
    },
    clear: function () {
      storage.clear()
      return true
    },
  }
  return localStorageMock
}

/**
 * 格式化时间
 * @param {string} dateString 时间字符串
 * @returns 时间
 */
export function formatDate (dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const min = date.getMinutes()
    const second = date.getSeconds()
    return `${year}/${month}/${day} ${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}:${second < 10 ? '0' + second : second}`
  }
  return ''
}
