import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router'
import store from './store'
import './assets/iconfont.css'
import tool from './utils/tool'

// import utils from '@/utils/validator.js'

import { initAPIInterceptor } from '@/interceptors/APIInterceptor'
import 'ant-design-vue/dist/antd.css'

initAPIInterceptor(store)
createApp(App)
  .use(Antd)
  .use(store)
  .use(router)
  .use(tool)
  .mount('#app')

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = JSON.parse(localStorage.getItem('token'))
    //  这里判断用户是否登录，验证本地存储是否有token
    if (token.access_token === undefined) { //  判断当前的token是否存在
      next({
        path: '/account/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() //  确保一定要调用 next()
  }
  const tokenData = JSON.parse(localStorage.getItem('token'))
  const timer = setInterval(() => {
    const now = new Date().valueOf()
    // console.log(now)
    // console.log(tokenData.expire_time)
    if (now > tokenData.expire_time) { // token过期自动退出
      window.location.href = '/account/login'
      window.localStorage.clear()
      history.pushState(null, null, document.URL)
      window.addEventListener(
        'popstate',
        function (e) {
          history.pushState(null, null, document.URL)
        },
        false,
      )
    }
  }, 1000)
  if (tokenData.expire_time === null || tokenData.expire_time === undefined) {
    clearInterval(timer)
  }
})
