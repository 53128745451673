import { createStore } from 'vuex'
import account from './modules/account'
import profile from './modules/profile'
import signalR from './modules/signalR'

export default createStore({
  modules: {
    account,
    profile,
    signalR,
  },
})
