// 状态声明
const state = () => ({
  profile: {},
})

const getters = {}

const mutations = {
  setProfile (state, { profile }) {
    state.profile = profile
    localStorage.setItem('userInfo', JSON.stringify(profile))
  },
}

const actions = {
  setProfile ({ state, commit }, profile) {
    commit('setProfile', { profile: profile })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
