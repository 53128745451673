<template>
  <a-dropdown placement="bottomRight">
    <span class="ant-pro-account-avatar">
      <a-avatar
        size="small"
        src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
        class="antd-pro-global-header-index-avatar"
      />
      <span>{{ currentUser.value.name }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center" @click="handleToControl">
          <template>
            <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
          控制台
        </a-menu-item>
        <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <template>
            <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
          个人中心
        </a-menu-item>
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <template>
            <SettingOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
          个人设置
        </a-menu-item>
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <template>
            <LogoutOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <!-- <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span> -->
</template>

<script>
// import { getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons-vue'
export default {
  name: 'AvatarDropdown',
  components: {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  setup () {
    const router = useRouter()
    const handleToControl = () => {
      router.push({ path: '/WorkManagement/WorkBench' })
    }

    const handleToCenter = () => {
      router.push({ path: '/UserManagement/PersonInformation' })
    }

    const handleToSettings = () => {
      router.push({ path: '/' })
    }

    const handleLogout = () => {
      window.location.href = '/account/login'
      window.localStorage.clear()
      // 清除cookie
      window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
      window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
      history.pushState(null, null, document.URL)
      window.addEventListener(
        'popstate',
        function (e) {
          history.pushState(null, null, document.URL)
        },
        false,
      )
    }

    return {
      handleToControl,
      handleToCenter,
      handleToSettings,
      handleLogout,
    }
  },
}
</script>
<style lang="scss" scoped>
.ant-pro-drop-down {
  .action {
    margin-right: 8px;
  }
  .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>
