<template>
  <router-view />
  <!-- <router-view :locale="locale" /> -->
</template>

<script>
import { useStore } from 'vuex'
// import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
// import 'moment/locale/zh-cn'
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload,
      // locale,
    }
  },

  setup () {
    const store = useStore()
    const initVuexStore = () => {
      const data = localStorage.getItem('token')
      const tenantId = localStorage.getItem('tenant')
      const connection = localStorage.getItem('connection')
      store.dispatch('account/setToken', data)
      store.dispatch('account/setTenant', {
        tenantId: tenantId,
      })
      store.dispatch('signalR/setConnection', connection)
    }
    return { initVuexStore }
  },
  created () {
    this.initVuexStore()
  },

}
</script>
