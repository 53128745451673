import { message } from 'ant-design-vue'
import api from '@/api/API'
import { useRouter } from 'vue-router'

export function initAPIInterceptor (store) {
  api.interceptors.request.use(
    async request => {
      const { token, language, tenant } = store.state.account
      if (!request.headers.Authorization && token && token.access_token) {
        request.headers.Authorization = `${token.token_type} ${token.access_token}`
      }

      if (!request.headers['Content-Type']) {
        request.headers['Content-Type'] = 'application/json'
      }

      if (!request.headers['Accept-Language'] && language) {
        request.headers['Accept-Language'] = language
      }

      if (!request.headers.__tenant && tenant && tenant.tenantId && tenant.tenantId !== 'null') {
        request.headers.__tenant = tenant.tenantId
      }

      return request
    },
    error => console.error(error),
  )

  api.interceptors.response.use(
    response => response,
    error => {
      const errorRes = error.response
      // const router = useRouter()
      // console.log(errorRes.headers._abperrorformat)
      if (errorRes) {
        if (errorRes.headers._abperrorformat && errorRes.status === 401) {
          store.dispatch('account/setToken', {})
        }
        if (errorRes.status === 401) {
          message.error('暂无权限')
        }
        // if (errorRes.status === 500) {
        //   // window.location.replace('/ServerFound')
        //   message.error('抱歉，服务器出了点小问题~')
        // }
        if (errorRes.status === 400) {
          message.error(errorRes.data.error_description)
        }
        if ((errorRes.status !== 400 || errorRes.status !== 401) && errorRes.config.url !== '/api/app/pub-method/get-wx-login-info') {
          message.error(errorRes.data.error.message)
        }
      }
      return Promise.reject(error)
    },
  )
}
