// const ENV = {
//   dev: {
//     apiUrl: 'https://localhost:44323',
//     // apiUrl: 'https://wtest.eipas.cn:8443',
//     oAuthConfig: {
//       issuer: 'https://wtest.eipas.cn',
//       // issuer: 'https://localhost:44331',
//       clientId: 'Platform_App',
//       clientSecret: '1q2w3e*',
//       scope: 'offline_access Platform',
//     },
//   },
//   prod: {
//     // apiUrl: 'https://wsmart.yaweicloud.com',
//     apiUrl: 'https://wtest.eipas.cn:8443',
//     oAuthConfig: {
//       issuer: 'https://wtest.eipas.cn',
//       // issuer: 'https://localhost:44331',
//       clientId: 'Platform_App',
//       clientSecret: '1q2w3e*',
//       scope: 'offline_access Platform',
//     },
//   },
// }
// const codePlatformUrl = 'https://wtest.eipas.cn:8090' // 测试环境
// // const codePlatformUrl = 'https://wsmart.yaweicloud.com/oldpcui' // 正式环境
// export const getEnvVars = () => {
//   // console.log(process.env.VUE_APP_ENVIRONMENT)
//   return process.env.NODE_ENV ? ENV.dev : ENV.prod
// }
const ENV = {
  // 开发环境
  dev: {
    apiUrl: 'https://wtest.eipas.cn:8443',
    // apiUrl: 'https://localhost:44323',
    codeDesignUrl: 'https://wtest.eipas.cn:8090',
    codePlatformUrl: 'https://wtest.eipas.cn:8590',
    imgUrl: 'https://wtest.eipas.cn:8446/iconfont/',
    whlUrl: 'https://wtest.eipas.cn:448/#/account/login',
    zwyUrl: 'http://testplatform.yaweicloud.com/auth-login/login',
  },

  // 线上环境
  prod: {
    apiUrl: 'https://wsmart.yaweicloud.com',
    codeDesignUrl: 'https://wsmart.yaweicloud.com/oldpcui',
    codePlatformUrl: 'https://wsmart.yaweicloud.com/newpcui',
    imgUrl: 'https://wservice.yaweicloud.com/iconfont/',
    whlUrl: 'https://wsmart.yaweicloud.com/wconnect/main/index.html#/account/login',
    zwyUrl: 'http://platform.yaweicloud.com/auth-login/login',
  },

  // 测试环境
  staging: {
    apiUrl: 'https://wtest.eipas.cn:8443',
    codeDesignUrl: 'https://wtest.eipas.cn:8090',
    codePlatformUrl: 'https://wtest.eipas.cn:8590',
    imgUrl: 'https://wtest.eipas.cn:8446/iconfont/',
    whlUrl: 'https://wtest.eipas.cn:448/#/account/login',
    zwyUrl: 'http://testplatform.yaweicloud.com/auth-login/login',
  },

}
// export const codePlatformUrl = 'https://wtest.eipas.cn:8090' // 测试环境
// export const codePlatformUrl = 'https://wsmart.yaweicloud.com/oldpcui' // 正式环境
export const getEnvVars = () => {
  if (process.env.VUE_APP_ENVIRONMENT === 'product') {
    return ENV.prod
  } else if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    return ENV.dev
  } else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
    return ENV.staging
  }
}
export const getCodePlatformUrl = () => {
  if (process.env.VUE_APP_ENVIRONMENT === 'product') {
    return ENV.prod.codePlatformUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    return ENV.dev.codePlatformUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
    return ENV.staging.codePlatformUrl
  }
}

export const getCodeDesignUrl = () => {
  if (process.env.VUE_APP_ENVIRONMENT === 'product') {
    return ENV.prod.codeDesignUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    return ENV.dev.codeDesignUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
    return ENV.staging.codeDesignUrl
  }
}

// 智维云地址
export const getZwyUrl = () => {
  if (process.env.VUE_APP_ENVIRONMENT === 'product') {
    return ENV.prod.zwyUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    return ENV.dev.zwyUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
    return ENV.staging.zwyUrl
  }
}

// 威互联地址
export const getWhlUrl = () => {
  if (process.env.VUE_APP_ENVIRONMENT === 'product') {
    return ENV.prod.whlUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    return ENV.dev.whlUrl
  } else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
    return ENV.staging.whlUrl
  }
}
