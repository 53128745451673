import { createRouter, createWebHistory } from 'vue-router'
import { DefaultLayout, LoginLayout } from '@/layouts'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // 控制台
    {
      path: '/home',
      name: 'home',
      redirect: '/EnterpriseManagement',
      component: () => import('@/views/Home'),
      children: [
        // 工作台
        {
          path: '/WorkManagement',
          name: 'WorkManagement',
          redirect: '/WorkManagement/WorkBench',
          component: () => import('@/views/WorkManagement/Index'),
          meta: {
            requiresAuth: true,
          },
          children: [
            // 资讯列表
            {
              path: '/WorkManagement/WorkBench',
              name: 'WorkBench',
              redirect: '',
              component: () => import('@/views/WorkManagement/WorkBench/Index'),
              meta: {
                requiresAuth: true,
              },
            },
            // 资讯列表
            {
              path: '/WorkManagement/InformationAnnList',
              name: 'InformationAnnList',
              redirect: '',
              component: () => import('@/views/WorkManagement/InformationAnnList/Index'),
              meta: { requiresAuth: true },
            },
            // 资讯详情页
            {
              path: '/WorkManagement/InformationAnnDetail',
              name: 'InformationAnnDetail',
              redirect: '',
              component: () => import('@/views/WorkManagement/InformationAnnDetail/Index'),
              meta: { requiresAuth: true },
            },
            // 工作待办
            {
              path: '/WorkManagement/WorkTask',
              name: 'WorkTask',
              redirect: '',
              component: () => import('@/views/WorkManagement/WorkTask/Index'),
              meta: { requiresAuth: true },
            },
          ],
        },
        // 企业管理
        {
          path: '/EnterpriseManagement',
          name: 'EnterpriseManagement',
          redirect: '/EnterpriseManagement/UseringDetail',
          component: () => import('@/views/EnterpriseManagement/Index'),
          meta: {
            requiresAuth: true,

          },
          children: [{
            path: '/EnterpriseManagement/UseringDetail',
            name: 'UseringDetail',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/UseringDetail/Index'),
          }, {
            path: '/EnterpriseManagement/BasicInformation',
            name: 'BasicInformation',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/BasicInformation/Index'),
          }, {
            path: '/EnterpriseManagement/MessageLog',
            name: 'MessageLog',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/MessageLog/Index'),
          }, {
            path: '/EnterpriseManagement/Application',
            name: 'Application',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/Application/Index'),
          }, {
            path: '/EnterpriseManagement/OrderInfo',
            name: 'OrderInfo',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/OrderInfo/Index'),
          }, {
            path: '/EnterpriseManagement/ApplyJoin',
            name: 'ApplyJoin',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/ApplyJoin/Index'),
          }, {
            path: '/EnterpriseManagement/ProductAttribute',
            name: 'ProductAttribute',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/ProductAttribute/Index'),
          }, {
            path: '/EnterpriseManagement/Payment',
            name: 'Payment',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/Payment/Index'),
          },
          // {
          //   path: '/EnterpriseManagement/PaymentSuccessFul',
          //   name: 'PaymentSuccessful',
          //   redirect: '',
          //   component: () => import('@/views/EnterpriseManagement/PaymentSuccessFul/Index'),
          // },
          {
            path: '/EnterpriseManagement/Permission',
            name: 'Permission',
            redirect: '',
            component: () => import('@/views/EnterpriseManagement/Permission/Index'),
          },
          ],
        },
        // 通讯录
        {
          path: '/AddressBook',
          name: 'AddressBook',
          redirect: '/AddressBook/Department',
          component: () => import('@/views/AddressBook/Index'),
          meta: {
            requiresAuth: true,
          },
          children: [{
            path: '/AddressBook/Department',
            name: 'Department',
            redirect: '',
            component: () => import('@/views/AddressBook/InteriorLinkMan/Department/Index'),
          }, {
            path: '/AddressBook/PostDuty',
            name: 'PostDuty',
            redirect: '',
            component: () => import('@/views/AddressBook/InteriorLinkMan/PostDuty/Index'),
          }, {
            path: '/AddressBook/ExternaLinkMan',
            name: 'ExternaLinkMan',
            redirect: '',
            component: () => import('@/views/AddressBook/ExternaLinkMan/Index'),
          }, {
            path: '/AddressBook/CompanyPartner',
            name: 'CompanyPartner',
            redirect: '',
            component: () => import('@/views/AddressBook/CompanyPartner/Index'),
          }],
        },
        // 个人信息
        {
          path: '/UserManagement',
          name: 'UserManagement',
          redirect: '/UserManagement/PersonInformation',
          component: () => import('@/views/UserManagement/Index'),
          meta: {
            requiresAuth: true,

          },
          children: [{
            path: '/UserManagement/PersonInformation',
            name: 'PersonInformation',
            redirect: '',
            component: () => import('@/views/UserManagement/PersonInformation/Index'),
          }, {
            path: '/UserManagement/MyQuestions/WorkOrder',
            name: 'WorkOrder',
            redirect: '',
            component: () => import('@/views/UserManagement/MyQuestions/WorkOrder/Index'),
          },
          {
            path: '/UserManagement/MyQuestions/Feedback',
            name: 'Feedback',
            component: () => import('@/views/UserManagement/MyQuestions/Feedback/Index'),
          }, {
            path: '/UserManagement/MyEnterprise',
            name: 'MyEnterprise',
            redirect: '/UserManagement/MyEnterprise/AlreadyJionEnterprise',
            component: () => import('@/views/UserManagement/MyEnterprise/Index'),
            children: [{
              path: '/UserManagement/MyEnterprise/AlreadyJionEnterprise',
              name: 'AlreadyJionEnterprise',
              redirect: '',
              component: () => import('@/views/UserManagement/MyEnterprise/AlreadyJionEnterprise/Index'),
            }, {
              path: '/UserManagement/MyEnterprise/JionEnterprise',
              name: 'JionEnterprise',
              redirect: '',
              component: () => import('@/views/UserManagement/MyEnterprise/JionEnterprise/Index'),
            }, {
              path: '/UserManagement/MyEnterprise/CreatEnterprise',
              name: 'CreatEnterprise',
              redirect: '',
              component: () => import('@/views/UserManagement/MyEnterprise/CreatEnterprise/Index'),
            }],
          }],
        },
        // 产品中心
        {
          path: '/ProductCentre',
          name: 'ProductCentre',
          redirect: '/ProductCentre/AllProduct',
          component: () => import('@/views/ProductCentre/Index'),
          meta: {
            requiresAuth: true,

          },
          children: [{
            path: '/ProductCentre/AllProduct',
            name: 'AllProduct',
            redirect: '',
            component: () => import('@/views/ProductCentre/AllProduct/Index'),
          }, {
            path: '/ProductCentre/Detail',
            name: 'Detail',
            redirect: '',
            component: () => import('@/views/ProductCentre/Detail/Index'),
          },
          ],
        },
        // 我的应用
        {
          path: '/FormManagement',
          name: 'FormManagement',
          redirect: '/FormManagement/FromDeploy',
          component: () => import('@/views/FormManagement/Index'),
          meta: {
            requiresAuth: true,

          },
          children: [{
            path: '/FormManagement/FromDeploy',
            name: 'FromDeploy',
            redirect: '',
            component: () => import('@/views/FormManagement/FromDeploy/Index'),
          },
            // {
            //   path: '/FormManagement/ApplicationDetails',
            //   name: 'ApplicationDetails',
            //   redirect: '',
            //   component: () => import('@/views/FormManagement/ApplicationDetails/Index'),
            // }
          ],
        },
        // 问题反馈
        {
          path: '/ProblemFeedback',
          name: 'ProblemFeedback',
          redirect: '',
          component: () => import('@/views/ProblemFeedback/Index'),
          meta: {
            requiresAuth: true,

          },
        },
        // 升级续费
        {
          path: '/UpgradeRenewal',
          name: 'UpgradeRenewal',
          redirect: '',
          component: () => import('@/views/UpgradeRenewal/Index'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    // 邀请（移动端）
    {
      path: '/account/InviteMobile',
      name: 'account-InviteMobile',
      component: () => import('@/views/account/InviteMobile'),
      meta: {

      },
    },
    // 邀请登录（移动端）
    {
      path: '/account/MobileLogin',
      name: 'account-MobileLogin',
      component: () => import('@/views/account/MobileLogin'),
      meta: {

      },
    },
    // 邀请加入-准备就绪
    {
      path: '/account/Success',
      name: 'account-Success',
      component: () => import('@/views/account/Success'),
    },
    {
      path: '/',
      name: '/account/login',
      redirect: '/account/login',
      component: () => import('@/views/account/Login'),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: '/',
    //   name: 'platform-index',
    //   component: DefaultLayout,
    //   children: [],
    // },
    {
      path: '/r/:id',
      name: 'account-IndexJump',
      redirect: '',
      component: () => import('@/views/account/IndexJump'),
    },
    // {
    //   path: '/ApplicationDetails',
    //   name: 'ApplicationDetails',
    //   redirect: '',
    //   component: () => import('@/views/ApplicationDetails/Index'),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: '/ApplicationDetails/:id',
      name: 'ApplicationDetails',
      redirect: '', // 新增
      component: () => import('@/views/ApplicationDetails/Index'),
      meta: {
        requiresAuth: true,
      },
      // 下方为新增
      children: [
        {
          // path: 'show:url:appCode',
          path: 'show/:url/:appCode',
          name: 'IframePage',
          redirect: '',
          component: () => import('@/views/ApplicationDetails/IframePage'),
          meta: {
            keepAlive: true,
          },
        },
      ],
    },
    // 登录注册相关
    {
      path: '/account',
      name: 'platform-account',
      component: LoginLayout,
      redirect: '/account/login',
      children: [
        {
          path: '/account/login',
          name: 'account-login',
          component: () => import('@/views/account/Login'),
          meta: {

          },
        },
        {
          path: '/account/findPassword',
          name: 'account-findPassword',
          component: () => import('@/views/account/FindPassword'),
          meta: {

          },
        },
        {
          path: '/account/register',
          name: 'account-register',
          component: () => import('@/views/account/Register'),
          meta: {

          },
        },
        {
          path: '/account/trial',
          name: 'account-trial',
          component: () => import('@/views/account/Trial'),
          meta: {

          },
        },
        {
          path: '/account/invite',
          name: 'account-invite',
          component: () => import('@/views/account/Invite'),
          meta: {

          },
        },
        {
          path: '/account/create',
          name: 'account-create',
          component: () => import('@/views/account/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/account/jion',
          name: 'account-jion',
          component: () => import('@/views/account/Jion'),
          meta: {
            requiresAuth: true,
          },
        },
        // {
        //   path: '/account/enterprise',
        //   name: 'account-enterprise',
        //   component: () => import('@/views/account/Enterprise'),
        //   meta: {

        //   },
        // },
      ],
    },
    {
      name: '404',
      path: '/:catchAll(.*)',
      component: () => import('@/views/notFound.vue'),
      meta: {

      },
    },
    {
      name: '500',
      path: '/ServerFound',
      component: () => import('@/views/ServerFound.vue'),
      meta: {

      },
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g // 这个是网上大部分遇到的
  const cssPattern = /Loading CSS chunk (\d)+ failed/g // 这个是我实际项目中遇到的
  const isChunkLoadFailed = error.message.match(pattern) || error.message.match(cssPattern)
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    // location.reload()
    var url = window.location.href
    window.location.href = url
    // const targetPath = $router.history.pending.fullPath;
    // $router.replace(targetPath);
  }
})

export default router
